<template>
  <v-card outlined>
    <v-card-text>
      <v-card 
        v-for="(type, i) in types"
        :key="i"
        @click="$emit('selected', type.type)" 
        class="mb-1" 
        outlined hover
      >
        <v-card-text>
          <v-icon left>{{ type.icon }}</v-icon>
          <span>{{ startCase(type.type) }}</span>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import startCase from 'lodash/startCase'

export default {
  data() {
    return {
      startCase,
      types: [
        {
          type: 'text_field',
          icon: 'mdi-form-textbox'
        },
        {
          type: 'textarea',
          icon: 'mdi-form-textarea'
        },
        {
          type: 'dropdown',
          icon: 'mdi-form-dropdown'
        },
        {
          type: 'checkbox',
          icon: 'mdi-checkbox-marked-outline'
        },
        {
          type: 'radio',
          icon: 'mdi-checkbox-marked-circle-outline'
        },
        {
          type: 'header',
          icon: 'mdi-format-text-variant-outline'
        },
        {
          type: 'attachment',
          icon: 'mdi-attachment'
        },
      ]
    }
  }
}
</script>