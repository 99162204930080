<template>
  <div class="mt-5">
    <div class="d-flex">
      <back-square url="RequestTypes" :params="{ id: $route.params.id }" />

      <div class="flex-grow-1">
        <!-- HEADER -->
        <div class="d-flex">
          <div>
            <h5 class="title"> <span class="font-weight-bold">Request Form Builder</span> "{{ product.name }}"</h5>
            <div v-if="!editingTitle" class="d-flex align-center gap-5">
              <v-breadcrumbs
                :items="breadcrumbs"
                class="pa-0"
              ></v-breadcrumbs>

              <v-btn @click="editingTitle = true" icon small>
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>

              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn 
                    @click="
                      $copyText(`${$window.origin}${$router.resolve({ name: 'RequestForm', params: { id: $route.params.id, product: $route.params.product } }).href}`)
                      .then(() => $store.dispatch('showSuccess', 'Copied to clipboard'))
                    " 
                    class="mr-2" 
                    v-on="on"
                    small
                    icon 
                  >
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </template>
                <span class="caption">Copy form URL</span>
              </v-tooltip>
            </div>
            <v-text-field
              :value="$store.getters['types/getProduct']($route.params.product).name"
              @blur="updateTitle($event)"
              width="250"
              outlined
              v-else
              dense
            >
              <template #append>
                <v-btn 
                  @click="editingTitle = false" 
                  :loading="updatingTitle"
                  icon x-small
                >
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex gap-5 align-center">
            <v-btn @click="confirmDelete()" color="red" small icon>
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>

        <div class="mt-5 d-flex gap-7">
          <v-btn
            @click="mode = 'editor'"
            :color="mode == 'editor' ? 'primary' : 'default'"
            depressed
            x-small
          >
            Editor
          </v-btn>
          
          <v-btn
            @click="mode = 'preview'"
            :color="mode == 'preview' ? 'primary' : 'default'"
            depressed
            x-small
          >
            Preview
          </v-btn>
        </div>

        <!-- CONTENT -->
        <v-row v-show="mode == 'editor'">
          <!-- BUILDER -->
          <v-col cols="8">
            <v-card
              max-width="1060"
              class="mt-2 box-shadow"
              outlined
            >
              <v-card-text>
                <!-- FIELDS -->
                <preloader v-if="status.getting" message="Please wait..." />

                <v-alert class="mb-0" v-if="!fields.length && !status.getting" type="info" border="left" text>
                  Click a field type on the right to insert your first field.
                </v-alert>

                <template v-if="fields.length && !status.getting">
                  <draggable v-model="sortableFields">
                    <v-hover 
                      v-for="(field, i) in sortableFields"
                      :key="field.id"
                      v-slot="{ hover }"
                    >
                      <v-card 
                        :class="{'mb-5' : i !== (sortableFields.length - 1), 'box-shadow': hover }"
                        class="transition"
                        outlined
                      >
                        <v-card-text>
                          <div class="d-flex align-center">
                            <v-icon style="cursor: -webkit-grab; cursor: grab;" class="mr-2">mdi-drag-vertical</v-icon>
                            
                            <div class="flex-grow-1">
                              <div class="d-flex align-center mb-3">
                                <div class="mr-5">
                                  <v-icon small left>{{ getTypeIcon(field.type) }}</v-icon>
                                  <span class="font-weight-bold primary--text">{{ startCase(field.type) }}</span>
                                </div>

                                <v-checkbox
                                  v-if="field.type !== 'header'"
                                  v-model="field.required"
                                  label="Required" 
                                  class="ma-0 pa-0"
                                  hide-details
                                  dense
                                ></v-checkbox>

                                <v-spacer></v-spacer>

                                <v-btn
                                  @click="confirmDeleteField(field)"
                                  small
                                  text
                                  icon
                                >
                                  <v-icon color="red" small>mdi-delete</v-icon>
                                </v-btn>
                              </div>
                              <v-text-field
                                v-model="field.label"
                                label="Label"
                                class="mb-1"
                                hide-details
                                outlined
                                dense
                              ></v-text-field>
                              
                              <v-text-field
                                v-model="field.description"
                                label="Description"
                                hide-details
                                outlined
                                dense
                              ></v-text-field>

                              <v-combobox
                                v-if="['dropdown', 'radio', 'checkbox'].includes(field.type)"
                                v-model="field.options"
                                label="Options"
                                deletable-chips
                                class="mt-5"
                                hide-details
                                small-chips
                                multiple
                                outlined
                                dense
                              ></v-combobox>

                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </draggable>
                </template>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- FIELD TYPE SELECTION -->
          <v-col cols="4">
            <v-sheet class="mt-2" max-width="300" color="transparent">
              <div v-if="fields.length" class="mb-5">
                <v-btn
                  @click="updateFields()"
                  class="box-shadow primary--text"
                  :loading="status.updating"
                  :disabled="status.adding"
                  color="white"
                  height="35"
                  depressed
                  block
                  small
                >Save Changes</v-btn>
              </div>
              
              <!-- FORM FIELDS -->
              <form-fields class="mb-5" @selected="fieldSelected" />

              <!-- EMPLOYEE ONLY -->
              <EmployeeOnly :form="product" class="mb-5" />

              <!-- PRIORITY -->
              <RequestPriority :form="product" class="mb-5" />

              <!-- REQUEST IMAGE -->
              <RequestImage :form="product" class="mb-5" />
              
              <!-- DEFAULT ASSIGNEE -->
              <DefaultAssignee :form="product" class="mb-5" />
              
              <!-- DEFAULT FOLLOWERS -->
              <DefaultFollowers :product="product" class="mb-5" />
              
              <!-- EXPECTED DELIVERY -->
              <ExpectedDelivery :product="product" class="mb-5" />
              
              <!-- WEBHOOK URL -->
              <WebhookUrl :product="product" class="mb-5" />
              
              <!-- ACTIVE CAMPAIGN TAG -->
              <ActiveCampaignTag v-if="false" :product="product" class="mb-5" />
            </v-sheet>
          </v-col>
        </v-row>

        <!-- PREVIEW -->
        <v-card
          v-show="mode == 'preview'"
          max-width="1060"
          class="mt-5 box-shadow"
          outlined
        >
          <v-card-text class="pa-10">
            <Form :fields="sortableFields" preview/>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <!-- DELETE PRODUCT -->
    <confirm-delete
      message="Are you sure you want to delete this product?"
      @confirmed="deleteProductConfirmed()"
      @cancel="closeDeleteProductDialog()"
      :deleting="typeStatus.deleting"
      :show="deleteProductDialog"
      textConfirmation="Product"
      captcha
    />

    <!-- CREATE PRODUCT -->
    <create-product
      @close="closeDialog()"
      :show="createDialog"
      :type="type"
    />

    <!-- DELETE FIELD -->
    <confirm-delete
      message="Are you sure you want to delete this field?"
      @cancel="deleteFieldDialog = false"
      @confirmed="deleteConfirmed()"
      :deleting="status.deleting"
      :show="deleteFieldDialog"
    />

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import startCase from 'lodash/startCase'
import { mapState, mapActions } from 'vuex'

import Form from './components/Form'
import FormFields from './FormFields'
import WebhookUrl from './components/WebhookUrl.vue'
import RequestImage from './components/RequestImage.vue'
import EmployeeOnly from './components/EmployeeOnly.vue'
import CreateProduct from './components/CreateProductDialog'
import DefaultAssignee from './components/DefaultAssignee.vue'
import RequestPriority from './components/RequestPriority.vue'
import DefaultFollowers from './components/DefaultFollowers.vue'
import ExpectedDelivery from './components/ExpectedDelivery.vue'
import ActiveCampaignTag from './components/ActiveCampaignTag.vue'

export default {
  metaInfo: {
    title: 'Request Form Builder'
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Form,
    draggable,
    FormFields,
    WebhookUrl,
    RequestImage,
    EmployeeOnly,
    CreateProduct,
    DefaultAssignee,
    RequestPriority,
    DefaultFollowers,
    ExpectedDelivery,
    ActiveCampaignTag,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      startCase,
      type: null,
      toDelete: null,
      title: null,
      mode: 'editor',
      createDialog: false,
      fieldsDialog: false,
      editingTitle: false,
      updatingTitle: false,
      deleteFieldDialog: false,
      deleteProductDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      typeStatus: state => state.types.status,
      products: state => state.types.products,
      fields: state => state.productform.fields,
      status: state => state.productform.status,
    }),

    sortableFields: {
      get() {
        return this.fields
      },

      set(newVal) {
        this.$store.commit('productform/updateFields', newVal)
      }
    },

    product: function () {
      return this.$store.getters['types/getProduct'](this.$route.params.product)
    },

    breadcrumbs: function () {
      return [
        {
          text: 'Request Types',
          to: { name: 'RequestTypes' },
          exact: true,
        },
        // {
        //   text: this.$store.getters['types/getTypeName'](this.$route.params.id),
        //   to: { name: 'RequestType', params: { id: this.$route.params.id } },
        //   exact: true,
        // },
        {
          text: this.product.name,
          to: { name: 'ProductFormBuilder', params: { id: this.$route.params.id, product: this.$route.params.product } },
          exact: true,
          disabled: true
        },
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'products': function (val) {
      if (val.length) {
        this.setProductData()
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('types', [
      'deleteProduct',
      'getProduct',
      'getType',
    ]),
    
    ...mapActions('productform', [
      'updateFields',
      'deleteField',
      'getFields',
      'addField',
    ]),

    showAddProductDialog() {
      this.createDialog = true
    },

    closeDeleteProductDialog() {
      this.deleteProductDialog = false
    },

    editProduct() {
      let product = this.$store.getters['types/getProduct'](this.$route.params.product)
      this.type = this.$store.getters['types/getType'](this.$route.params.id)
      this.$store.commit('types/setProduct', product)
      this.showAddProductDialog()
    },

    confirmDelete() {
      let product = this.$store.getters['types/getProduct'](this.$route.params.product)
      this.toDelete = product
      this.deleteProductDialog = true
    },

    closeDialog() {
      this.createDialog = false
      this.$store.commit('types/resetProduct')
    },

    deleteProductConfirmed() {
      Promise.resolve(this.deleteProduct(this.toDelete))
      .then(() => {
        this.closeDeleteProductDialog()
        this.$router.push({ name: 'RequestTypes'})
      })
    },

    closeFieldsDialog() {
      this.fieldsDialog = false
    },

    setProductData() {
      if (this.products.length) {
        let product = this.products.find(p => p.id == this.$route.params.product)
        if (product) this.$store.commit('productform/setProduct', product)
      }
    },

    fieldSelected(e) {
      this.closeFieldsDialog()
      this.addField(e)
    },

    confirmDeleteField(field) {
      this.toDelete = field
      this.deleteFieldDialog = true
    },

    deleteConfirmed() {
      Promise.all([
        this.deleteField(this.toDelete)
      ])
      .then(() => {
        this.toDelete = null
        this.deleteFieldDialog = false
      })
    },

    getTypeIcon(type) {
      let types = {
        'attachment': 'mdi-attachment',
        'textarea': 'mdi-form-textarea',
        'dropdown': 'mdi-form-dropdown',
        'text_field': 'mdi-form-textbox',
        'checkbox': 'mdi-checkbox-marked-outline',
        'header': 'mdi-format-text-variant-outline',
        'radio': 'mdi-checkbox-marked-circle-outline',
      }

      return types[type]
    },

    updateTitle(e) {
      this.updatingTitle = true

      Promise.resolve(
        this.$store.dispatch('types/updateProductField', {
          field: 'name',
          product: this.product,
          value: e.target.value, 
        })
      )
      .then(() => {
        this.updatingTitle = false
        this.editingTitle = false
      })
    }

  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    let data = {
      type: this.$route.params.id,
      product: this.$route.params.product
    }
    
    this.getType(this.$route.params.id)
    this.getProduct(data)
    this.getFields(data)
    this.setProductData()
  }
}
</script>